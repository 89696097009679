// Generated by Framer (e22b717)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-llHPl"

const variantClassNames = {P9_yB_6SR: "framer-v-by2nwe"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "P9_yB_6SR", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 1440, pixelWidth: 2560, positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/lagto1syJC0VUfIOulzfriqFJw.png", srcSet: "https://framerusercontent.com/images/lagto1syJC0VUfIOulzfriqFJw.png?scale-down-to=512 512w,https://framerusercontent.com/images/lagto1syJC0VUfIOulzfriqFJw.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/lagto1syJC0VUfIOulzfriqFJw.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/lagto1syJC0VUfIOulzfriqFJw.png 2560w"}} className={cx(scopingClassNames, "framer-by2nwe", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"P9_yB_6SR"} ref={refBinding} style={{"--border-bottom-width": "1px", "--border-color": "rgba(158, 161, 186, 0.25)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 28, borderBottomRightRadius: 28, borderTopLeftRadius: 28, borderTopRightRadius: 28, ...style}}><motion.div background={{alt: "", positionX: "left", positionY: "center"}} className={"framer-k8ii12"} data-framer-name={"Card-wrapper"} layoutDependency={layoutDependency} layoutId={"Vya1WM8BB"}/></Image></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-llHPl.framer-vk480w, .framer-llHPl .framer-vk480w { display: block; }", ".framer-llHPl.framer-by2nwe { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 300px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 454px; will-change: var(--framer-will-change-override, transform); }", ".framer-llHPl .framer-k8ii12 { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: column; flex-wrap: nowrap; gap: 16px; height: 1px; justify-content: center; overflow: hidden; padding: 16px; position: relative; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-llHPl.framer-by2nwe, .framer-llHPl .framer-k8ii12 { gap: 0px; } .framer-llHPl.framer-by2nwe > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-llHPl.framer-by2nwe > :first-child, .framer-llHPl .framer-k8ii12 > :first-child { margin-top: 0px; } .framer-llHPl.framer-by2nwe > :last-child, .framer-llHPl .framer-k8ii12 > :last-child { margin-bottom: 0px; } .framer-llHPl .framer-k8ii12 > * { margin: 0px; margin-bottom: calc(16px / 2); margin-top: calc(16px / 2); } }", ".framer-llHPl[data-border=\"true\"]::after, .framer-llHPl [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 300
 * @framerIntrinsicWidth 454
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerqcO0AQr0T: React.ComponentType<Props> = withCSS(Component, css, "framer-llHPl") as typeof Component;
export default FramerqcO0AQr0T;

FramerqcO0AQr0T.displayName = "image 3 step 3";

FramerqcO0AQr0T.defaultProps = {height: 300, width: 454};

addFonts(FramerqcO0AQr0T, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})